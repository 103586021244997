<template>
  <div class="mobile-width">
    <apexchart
      :options="chartData.chartOptions"
      :series="chartData.chartSeries"
      :width="chartData.width"
      :height="chartData.height"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartData: {
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
@media only screen and (min-device-width: 300px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-width {
    width: 500px;
  }
}
</style>
